import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import DiscountBanner from "./DiscountBanner";
import "./DiscountBanner.css"; // Import custom styles
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { Link, useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; // Import the MUI right arrow icon
import CheckIcon from "@mui/icons-material/Check";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
// import { Headphones } from "@mui/icons-material/Headphones";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import TuneIcon from "@mui/icons-material/Tune";
import PhotoFilterIcon from "@mui/icons-material/PhotoFilter";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import Footer from "../layout/footer";
import OfferContactUs from "./OfferContactUs";

function OfferLanding() {
  const navigate = useNavigate();

  const premiumPoints = [
    {
      text: "3 Months free on 1-year subscription",
      icon: <CheckCircleOutlineRoundedIcon className="text-success" />,
    },
    {
      text: "Flat 50% discount on 1-year subscription",
      icon: <CheckCircleOutlineRoundedIcon className="text-success" />,
    },
    {
      text: "We offer customization",
      // text: "Customization available with additional cost ",
      icon: <CheckCircleOutlineRoundedIcon className="text-success" />,
    },
    {
      text: "Initial setup assistance by our team",
      icon: <CheckCircleOutlineRoundedIcon className="text-success" />,
    },
    {
      text: (
        <a href="https://www.buzzhrna.com/" target="_blank">
          Free access to <span className="highlight"> www.buzzhrna.com</span>
        </a>
      ),
      icon: <CheckCircleOutlineRoundedIcon className="text-success" />,
    },
  ];

  const oldPoints = [
    {
      text: "1 Month free on 1-year subscription",
      icon: <CheckCircleOutlineRoundedIcon className="text-success" />,
    },
    {
      text: "Flat 10% discount on 1-year subscription",
      icon: <CheckCircleOutlineRoundedIcon className="text-success" />,
    },
    {
      text: "No customization available",
      icon: <CancelOutlinedIcon className="text-danger" />,
    },
    {
      text: "No assistance with initial setup",
      icon: <CancelOutlinedIcon className="text-danger" />,
    },
  ];

  useEffect(() => {
    AOS.init({
      initClassName: false,
      useClassNames: true,
      duration: 1200, // Animation duration
      once: false, // Animation happens only once
    });
  }, []);

  const handleClaimClick = (e) => {
    e.preventDefault();
    const claimtextMessage =
      "Festive Offer: Get 50% Off on Your First Year Subscription! – Grab It Now!";
    navigate("/company-register", { state: { claimtextMessage } }); // Pass the message using state
  };

  return (
    <div className="container-fluid offer_container">
      {/* Logo Section */}
      <div className="row justify-content-start align-items-center justify-content-between px-4 py-2">
        {/* <div className="row align-items-center justify-content-between"> */}
        <div className="col-2">
          <a href="https://timetango.in/">
            <img
              className="navbar-brand time-tango-logo"
              src="/assets/logo3.svg"
              alt="Time Tango"
            />
          </a>
        </div>
        <div className="col-10 text-end">
          <div className="">
            <div className="wp_wrap">
              <a href="tel:97663 15309">
                {" "}
                <WhatsAppIcon className="text-success" />
                <span> (+91) 97663 15309 </span>
              </a>
            </div>
            <div className="contact-numberr">
              <a href="tel:91300 81345">
                <CallRoundedIcon className="text-success" />
                <span> (+91) 91300 81345</span>
              </a>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>

      {/* Image Section */}
      <div className="row offer_hero">
        <img src="assets/img/offer/offer3.jpg" alt="Offer Background" />
        <Button
          variant="contained"
          className="current_btn sign_up_btn"
          onClick={handleClaimClick}
        >
          Sign Up Now
        </Button>
      </div>

      <div className="container mt-5 pricing_wrap section-padding ">
        <div className="row justify-content-evenly gx-2 mb-5">
        <div
            className="col-md-4 col-lg-4 col-xs-12 col-sm-12  current_card card p-4 justify-content-between rounded-lg shadow"
            data-aos="zoom-in-up"
          >
            <h3 className="mb-4 text-center">Standard Deal</h3>
            <ul>
              {oldPoints.map((item,index) => (
                <li key={index}>
                  <span className="mr-3"> {item.icon} </span> {item.text}{" "}
                </li>
              ))}
            </ul>
          </div>

      

          {/* VS Symbol */}
          <div
            className="col-lg-2 col-md-2 vs-symbol-wrap d-flex align-items-center justify-content-center"
            data-aos="zoom-in-up"
          >
            <div className="vs-symbol">
              <span className="badge bg-warning text-dark p-3 fs-3">VS</span>
            </div>
          </div>
      
          <div
            className="col-md-4 col-lg-4 col-xs-12 col-sm-12 premium_card card p-4 rounded-lg h-100 shadow-lg"
            data-aos="zoom-in-up"
          >
            <div className="ribbon red">
              <span>Exclusive</span>
            </div>
            <h3 className="mb-4 text-center">Festive Deal</h3>
            <ul>
              {premiumPoints.map((item,index) => (
                <li key={index}>
                  <span> {item.icon} </span>
                  {item.text}
                </li>
              ))}
            </ul>
          </div>


        </div>
        <div className="row justify-content-center deal_btn">
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 d-flex justify-content-center"
            data-aos="zoom-in-up"
          >
            <Button
              variant="contained"
              className="current_btn w-100"
              onClick={handleClaimClick}
            >
              Claim Your Deal
            </Button>{" "}
          </div>
        </div>
      </div>
      {/* contact form  */}
      <div className="container mb-5">
        <div className="row px-lg-5">
          <div
            className="offer_contact col-lg-10 pt-4 pb-5 px-4 mx-auto text-center contact-heading contact-heading-nm card shadow-lg"
            data-aos="zoom-in-up"
          >
            {/* <p>Have a question, feel free to buzz&nbsp;us!</p>  */}
            <p className="textHelp">
              Need some clarity? We're eager to help – don’t hesitate to reach
              out!
            </p>
            <h3 className="h1">Contact Us</h3>

            <OfferContactUs />
          </div>
        </div>
      </div>
      {/* contact form  */}
      {/* Footer */}
      <Footer />
      {/* <footer className="footer bg-dark text-white text-center p-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <h5>Follow Us</h5>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/profile.php?id=61550584126267"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookRoundedIcon />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/showcase/99884387/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.instagram.com/timetango.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <p className="mt-3">Product by Gunadhya Software Solutions Pvt Ltd</p>
        </div>
      </footer> */}
    </div>
  );
}

export default OfferLanding;
