// import "./layout.css";
import { Link } from "react-router-dom";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";

const Footer = () => {
  return (
    <>
      <section id="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="footer-logo">
                <a className="f-logo" href="#home">
                  <img
                    className="footerlogoCls"
                    src="/assets/logo3.svg"
                    alt="Time Tango"
                  />
                </a>
                {/* <p>
                    Time Tango is a comprehensive Check-In and Check-Out App
                    designed for office attendance management, with an innovative
                    feature that ensures foolproof authentication for those
                    working from home.
                  </p> */}

                <div className="location links ">
                  {/* <h3>Location</h3>* */}
                  {/* <p className="noTopPadding">
                    <a
                      href="https://maps.app.goo.gl/uhDQhGPL7yDBuBbS9"
                      target="_blank"
                      className="hover1 text-capitalize"
                    >
                      Office 201, Mont Vert Zenith, Baner Rd, Riviresa Society,
                      Baner, Pune, Maharashtra 411045
                    </a>
                  </p> */}
                  <ul className="d-flex social_link_wrapper">
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=61550584126267"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="assets/icons/social/fb.png" alt="fb" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/showcase/99884387/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="assets/icons/social/linkedin.png"
                          alt="linkedin"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/timetango.in/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="assets/icons/social/instagram.png"
                          alt="instagram"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <br /> */}
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4">
              <div className="links">
                <h3>Links</h3>
                <ul className="">
                  <li>
                    <a className="nav-link footernavlinks hover1" href="#home">
                      Home
                    </a>
                  </li>

                  <li>
                    <a
                      className="nav-link footernavlinks hover1"
                      href="#features"
                    >
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link footernavlinks hover1"
                      href="#price-section"
                    >
                      Pricing
                    </a>
                  </li>
                  <li>
                    <Link to={"/logIn"} className="link hover1">
                      Login
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-2 col-sm-6 col-md-6">
              <div className="links">
                <h3>Quick Links</h3>
                <ul className="">
                  <Link to={"/logIn"} className="link hover1">
                    Login
                  </Link>
                </ul>
              </div>
            </div> */}
            <div className="col-lg-4 col-sm-12 col-md-4">
              <div className="location links">
                <h3>Contact</h3>
                <p className="noTopPadding">
                  <a
                    href="https://maps.app.goo.gl/uhDQhGPL7yDBuBbS9"
                    target="_blank"
                    className="hover1  text-capitalize"
                    rel="noreferrer"
                  >
                    Office 201, Mont Vert Zenith, Baner Rd, Riviresa Society,
                    Baner, Pune, Maharashtra 411045
                  </a>
                </p>
                <p className="">
                  <a href="tel:+919130081345" className="hover1">
                    <i className="fa fa-phone"></i> +91 9130081345
                  </a>
                </p>
                {/* <h3>Email</h3> */}
                <p className="">
                  <a href="mailto:sales@gunadhyasoft.com" className="hover1">
                    <i className="fa fa-envelope"></i> sales@gunadhyasoft.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <hr className="my-0" />
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-12 col-md-12 moja-loss pt-0 pt-md-3 order-2 order-md-0">
              <div className="fop-btm">
                <h2 className="copy_right">
                  <span>
                    {" "}
                    Product by{" "}
                    <a
                      href="https://www.gunadhyasoft.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="hover1 text-color"
                    >
                      Gunadhya Software Solutions Pvt&nbsp;Ltd
                    </a>
                  </span>
                </h2>
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-5 moja-loss text-center mt-3 my-md-0">
              <a
                className="f-logo mb-0"
                href="https://www.gunadhyasoft.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="gunlogoCls"
                  src="https://instituterp.com/asset_vendor/images/gunadhya_logo.png"
                  alt="gunadhya-logo"
                />
              </a>
            </div> */}
          </div>
        </div>
      </section>
      {
        <>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
        </>
      }
    </>
  );
};

export default Footer;
