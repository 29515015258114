import { CssBaseline } from "@mui/material";
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import routes from "./routes";
import { EmpListProvider } from "./contexts/EmployeeListContext";
import { TawkToProvider } from "./contexts/TawkToContext"; 
import './global.css';
// import "../fake-db";
const App = () => {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <AuthProvider>
        <EmpListProvider>
          <MatxTheme>
            <CssBaseline />
            <TawkToProvider tawkToId="65d480269131ed19d96eee9f"> 
              {content}
            </TawkToProvider>
          </MatxTheme>
        </EmpListProvider>
      </AuthProvider>
    </SettingsProvider>
  );
};

export default App;
