import { contactUs } from "./../../../services/Services";
import {
  generateErrorMessage,
  generateSuccessMessage,
} from "app/utils/GenerateMessage";
import { regexExp } from "app/utils/utils";
import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import * as Yup from "yup";
import "./../landingPage.css";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";

let innitialValue = {
  name: "",
  email: "",
  phone_number: "",
  message: "",
  description: "",
};
const customStyles = {
  transform: "scale(0.8)",
  WebkitTransform: "scale(0.8)",
  transformOrigin: "0 0",
  WebkitTransformOrigin: "0 0",
};
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required!")
    // .matches(/^[A-Za-z]+$/, "First name is not valid")
    .min(2, "Must be more then two character"),
  email: Yup.string()
    .email("Valid email is required!")
    .required("Email is required!"),
  phone_number: Yup.string()
    .matches(regexExp, "Mobile number is not valid")
    .required("Phone number is required!"),
  //   phone_number: Yup.string().required("Phone number is required!"),
});
const OfferContactUs = () => {
  const [loader, setLoader] = useState(false);
  const recaptcha = useRef(null);
  const [isVerified, setIsVerified] = useState(false);
  const formSubmitHandler = (value, actions) => {
    if (isVerified) {
      setLoader(true);
      contactUs(value)
        .then((res) => {
          if (res) {
            setLoader(false);
            if (res.status === 200) {
              setLoader(false);
              generateSuccessMessage(res?.data?.message || "");
              actions.resetForm({
                value: {
                  name: "",
                  email: "",
                  phone_number: "",
                  message: "",
                  description: "",
                },
              });
            }
          } else {
            setLoader(false);
            generateErrorMessage(`${"Something went wrong."}`);
          }
        })
        .catch((error) => {
          setLoader(false);
          generateErrorMessage(error?.response?.data?.message || "");
        });
    } else {
      generateErrorMessage(`Please verify you are not robot`);
    }
  };
  const handleRecaptchaChange = (response) => {
    if (response) {
      setIsVerified(true);
    }
  };
  return (
    <Formik
      initialValues={innitialValue}
      onSubmit={formSubmitHandler}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        errors,
        handleSubmit,
        handleBlur,
        touched,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="row pt-5">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <div className="col-lg-6 new-nb contact-input">
                  <div className="input-group contact-input mb-2">
                    <input
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name || ""}
                      type="text"
                      className="form-control"
                      placeholder="Name *"
                      id="validationCustom03"
                    />
                  </div>
                  {touched.name && errors.name && (
                    <div
                      className="text-start pt-0 mt-0"
                      style={{ color: "red",marginLeft: "1px" }}
                    >
                      {errors.name}
                    </div>
                  )}
                </div>
                <div className="col-lg-6 new-nb contact-input">
                  <div className="input-group contact-input mb-2">
                    <input
                      name="email"
                      value={values.email || ""}
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Email Address *"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  {touched.email && errors.email && (
                    <div className="text-start" style={{ color: "red",marginLeft: "1px" }}>
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className="col-lg-6 new-nb contact-input">
                  <div className="input-group contact-input mb-2">
                    <input
                      name="phone_number"
                      value={values.phone_number || ""}
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Phone Number *"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  {touched.phone_number && errors.phone_number && (
                    <div className="text-start" style={{ color: "red",marginLeft: "1px" }}>
                      {errors.phone_number}
                    </div>
                  )}
                </div>
                <div className="col-lg-6 new-nb contact-input">
                  <div className="input-group contact-input mb-2">
                    <input
                      name="message"
                      value={values.message || ""}
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Message"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea
                      name="description"
                      value={values.description || ""}
                      onChange={handleChange}
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="Description"
                      rows={3}
                    />
                  </div>
                </div>
                <div className="col-lg-6 new-nb contact-input">
                  <div style={{ marginTop: "18px" }}>
                    <ReCAPTCHA
                      className="responsive-recaptcha"
                      ref={recaptcha}
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      onChange={handleRecaptchaChange}
                      onExpired={() => setIsVerified(false)}
                      style={customStyles}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="con-btn-main text-center">
                    <button type="submit" id="contactus" className="mix-a mt-2 border-0">
                      {loader && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      {loader ? "Loading..." : "Submit Now"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default OfferContactUs;
