import axios from "axios";
import {
  clearLoggedInUserInvalidCredential,
} from "app/utils/utils";
import { generateErrorMessage } from "app/utils/GenerateMessage";
import { toast } from "react-toastify";

// export const baseURL = "http://129.154.237.106:8000";
export const baseURL = "https://timetango.in/api";
// export const baseURL = "http://localhost:8000";
// export const baseURL = "https://sahbazar.in/api";
export const axiosApiInstance = axios.create({ baseURL: baseURL });

export const axiosMultipart = axios.create({ baseURL: baseURL });

export default axiosApiInstance.interceptors.request.use(
  async (config) => {
    const tokan = localStorage.getItem("tokan");
    if (tokan) {
      config.headers["Authorization"] = `Bearer ${tokan}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // debugger;
    // console.log(error)
    // if (error?.message === "Request failed with status code 401") {
    //   if (error.response.data.message == "Invalid credentials!") {
    //     toast.error("invalid credentials, Please try again!", {
    //       position: toast.POSITION.TOP_RIGHT,
    //       toastId: "logInError",
    //     });
    //   } else {
    //     clearLoggedInUserInvalidCredential();
    //     setTimeout(function () {
    //       clearLoggedInUser();
    //     }, 2000);
    //   }
    //   return;
    // }
    // if (error?.message === "Network Error") {
    //   return toast.error("Network Error", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     toastId: "networkError",
    //   });
    //   // clearLoggedInUser();
    // } else if (error?.response?.status === 401) {
    //   if (
    //     error?.response?.data?.message === "Please, login again!" ||
    //     error?.response?.data?.message === "Auth Header missing!"
    //     // error?.response?.data?.message === "Invalid credentials!"
    //   ) {
    //     setTimeout(function () {
    //       clearLoggedInUser();
    //     }, 700);
    //     return toast.error("Please, login again!", {
    //       position: toast.POSITION.TOP_RIGHT,
    //       toastId: "logInError",
    //     });
    //   } else {
    //     return Promise.reject(error);
    //   }
    // } else if (error?.response?.status === 404) {
    //   if (
    //     error?.response?.data?.message === "This role might have been removed"
    //   ) {
    //     clearLoggedInUser();

    //     return toast.error("This role might have been removed", {
    //       position: toast.POSITION.TOP_RIGHT,
    //       toastId: "NoTokenError",
    //     });
    //   } else {
    //     return Promise.reject(error);
    //   }
    // } else if (error?.response?.status === 502) {
    //   return toast.error("Bad Gateway", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     toastId: "serverDown",
    //   });
    // } else return Promise.reject(error);

    if (error?.message === "Network Error") {
      toast.error("Network Error", {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "networkError",
      });
      return clearLoggedInUserInvalidCredential();
    }
    // if (
    //   error.response.data.message === "Invalid credentials!" ||
    //   error.response.data.message === "Invalid Token!"
    // ) {
    //   toast.error(error.response.data.message, {
    //     position: toast.POSITION.TOP_RIGHT,
    //     toastId: "logInError",
    //   });
    //   return clearLoggedInUser();
    // }
    // if (error?.response?.status === 401) {
    //   toast.error(error.response.data.message, {
    //     position: toast.POSITION.TOP_RIGHT,
    //     toastId: "Unauthorized",
    //   });
    //   return clearLoggedInUser();
    // }
    // if (error?.response?.status === 404) {
    //   toast.error(error.response.data.message, {
    //     position: toast.POSITION.TOP_RIGHT,
    //     toastId: "roleExpire",
    //   });
    //   return clearLoggedInUser();
    // }
    // if (error?.response?.status === 502) {
    //   toast.error("Bad Gateway", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     toastId: "serverDown",
    //   });
    //   return clearLoggedInUser();
    // }
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 502
    ) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "error",
      });
      clearLoggedInUserInvalidCredential();
    } else {
      return Promise.reject(error);
    }
  }
);

axiosMultipart.interceptors.request.use(
  async (config) => {
    const tokan = localStorage.getItem("tokan");
    if (tokan) {
      config.headers["Authorization"] = `Bearer ${tokan}`;
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// export default axios.create({
//   baseURL: baseURL,
// });
// const apiUrl = 'http://localhost:5001';
// export default axios.interceptors.request.use(
//   config => {
//     const { origin } = new URL(config.url);
//     const allowedOrigins = [apiUrl];
//     const token = localStorage.getItem('token');
//     console.log("token===>",token)
//     if (allowedOrigins.includes(origin)) {
//       config.headers.authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );
